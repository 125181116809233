import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const getVideo = (slug, deeplink) => {
    return axios.get(API_URL + "video/" + slug, {params: {deeplink: deeplink}})
}

const publicService = {getVideo};
export default publicService;
