import React, {useEffect, useState} from 'react';
import Layout from "../../components/Layout/Layout";
import {useParams} from "react-router-dom";
import userService from "../../services/userServices";
import {format} from "date-fns";
import classes from './Video.module.scss'
import {useNavigate} from 'react-router-dom';
import Loading from "../../components/Loading/Loading";
import {useAuth} from "../../hooks/useAuth";
import useMetaTags from 'react-metatags-hook';

const Video = () => {
        const {logout} = useAuth();
        const [video, setVideo] = useState({});
        const [videoCategories, setVideoCategories] = useState([]);
        const [copy, setCopy] = useState({text: 'Publikus link megosztása', isCopied: false});
        const [isLoading, setIsLoading] = useState(false);
        const videoSlug = useParams().slug;
        const [videoDate, setVideoDate] = useState('');
        const navigate = useNavigate();

        localStorage.removeItem('from')

        useEffect(() => {
            setIsLoading(true);
            userService.getVideo(videoSlug).then(result => {
                setVideo(result.data.video);
                setVideoCategories(result.data.categories)
                setIsLoading(false);
                setVideoDate(format(new Date(result.data.video.published_at.replace(/-/g, "/")), 'yyyy. MM. dd.'))
            }, error => {
                error.response.data.message === 'Unauthenticated' && logout()
            });
        }, []);

        useEffect(() => {
            setTimeout(() => {
                setCopy({text: 'Publikus link megosztása', isCopied: false});
            }, 1500)
        }, [copy.isCopied === true])

        useMetaTags({
            title: `Jacsomedia Videóportál - ${video.title}`,
            description: `${video.description}`,
            openGraph: {
                title: `Jacsomedia Videóportál - ${video.title}`,
                description: `${video.description}`,
                image: `${video.thumbnail_url}`,
                'site_name': 'Jacsomedia Videóportál'
            }
        }, [video]);

        return (
            <Layout>
                <div className={"flex w-full " + classes["background"]}>{
                    isLoading ? <Loading/> : <>
                        <div className={"container mx-auto px-10 mt-8 flex flex-col"}>
                            <div className={"flex flex-row justify-between mb-3 order-1 md:order-none"}>
                                <button onClick={() => navigate('/')}
                                        className={"font-base text-baseText hover:text-brandPrimary"}>Vissza a főoldalra
                                </button>
                            </div>
                            <div className={"mb-4 flex flex-col items-center order-3 md:order-none"}>
                                <iframe src={video.embed_code} width="100%"
                                        className={'h-[calc(100vw*0.5625)] max-h-[400px] max-w-[711px]'}
                                        height="400" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                                        allowFullScreen/>
                                {video.deeplink != null && <>
                                    <button onClick={() => {
                                        navigator.clipboard.writeText('https://videoportal.jmdemo.hu/public/video/' + video.url_slug + '?accesstoken=' + video.deeplink);
                                        setCopy({text: 'Vágólapra másolva!', isCopied: true})
                                    }}
                                            className={"btn-primary mt-4"}>{copy.text}
                                    </button>
                                </>}
                            </div>
                            <div className={"mb-3 hidden md:block"}>
                                {videoCategories.map(category => <><span
                                    className={"font-base text-base font-black border bg-white border-brandSecondary py-2.5 px-6 min-h-10 inline-block peer-checked:bg-brandPrimary peer-checked:text-white hover:bg-brandSecondary hover:text-white transition-all"}>{category}</span></>)}
                            </div>
                            <h1 className={"font-base text-lg lg:text-2xl text-brandSecondary font-bold mb-auto border-l-[5px] border-brandSecondary pl-1 mb-3 order-2 md:order-none"}>{video.title}</h1>
                            <div className="order-4 md:order-none">
                                <div className={"font-base text-sm mb-4"}>{video.description}</div>
                                <div className={"font-base italic text-sm mb-4"}>Előadó: <span
                                    className={"font-base font-bold"}>{video.presenter}</span>
                                </div>
                                <div className={"font-base text-sm italic mb-4"}>{videoDate}</div>
                                <div className={"mb-10 block md:hidden"}>
                                    {videoCategories.map(category => <><p
                                        className={"rounded-full bg-lightgray w-fit mb-2 border border-brandPrimary py-1 px-3 text-xs font-oswaldM hover:bg-brandPrimary hover:text-white"}>{category}</p></>)}
                                </div>
                            </div>


                        </div>
                    </>
                }


                </div>
            </Layout>
        );
    }
;

export default Video;
