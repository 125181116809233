import React from 'react';
import {Navigate, useOutlet} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {useLocation} from "react-router-dom";

const ProtectedRoute = () => {
    const {user} = useAuth();
    const outlet = useOutlet();
    const location = useLocation()

    if (!user) {
        localStorage.setItem('from', location.pathname);
        return <Navigate to="/bejelentkezes" state={{from: location}} replace/>;
    }
    return (
        <div>
            {outlet}
        </div>
    );
}

export default ProtectedRoute;
