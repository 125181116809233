import {createContext, useContext, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import useLocalStorage from "./useLocalStorage";
import {useLocation} from "react-router-dom";

const UseAuth = createContext();

export const AuthProvider = ({children}) => {

    let location = useLocation();
    const [user, setUser] = useLocalStorage("user", null);
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || localStorage.getItem('from') || "/";


    const login = async (data) => {
        await setUser({data});
        await navigate(from, {replace: true});
    };


    const logout = () => {
        setUser(null);
        localStorage.removeItem('user');
        navigate("/bejelentkezes", {replace: true});
    };

    const value = useMemo(
        () => ({
            user,
            login,
            logout
        }),
        [user]
    );

    return <UseAuth.Provider value={value}>{children}</UseAuth.Provider>;
};

export const useAuth = () => {
    return useContext(UseAuth);
};
