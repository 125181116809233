import './App.css';
import * as PropTypes from "prop-types";
import {Routes, Route} from "react-router-dom";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import ProtectedRoute from "./components/Routing/ProtectedRoute";
import Video from "./pages/Video/Video";
import Result from "./pages/Result/Result";
import NotFound from "./pages/NotFound/NotFound";
import Deeplink from "./pages/Deeplink/Deeplink";

Routes.propTypes = {children: PropTypes.node};

function App() {
    return (
        <Routes>
            <Route path="/bejelentkezes" element={<Login/>}/>
            <Route path="/public/video/:slug" element={<Deeplink/>}/>
            <Route path="/" element={<ProtectedRoute/>}>
                <Route path="/" element={<Home/>}/>
                <Route path="/video/:slug" element={<Video/>}/>
                <Route path="/result" element={<Result/>}/>
            </Route>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    );
}

export default App;
