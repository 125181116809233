import axios from "axios";
import authHeader from "./authHeader";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const loginUser = (email, password) => {
    return axios.post(API_URL + "user-login", {email, password})
        .then((response) => {
            return response.data;
        });
}

const getUserData = () => {
    return axios.get(API_URL + "user-data", {headers: authHeader()})
}

/*const forgotPW = (email) => {
    return axios.post(API_URL + "forgot-password", {email})
}

const newPassword = (password, password_confirmation, token) => {
    return axios.post(API_URL + "reset-password", {
        password, password_confirmation, token
    })
}
const setNewPassword = (password, password_confirmation) => {
    return axios.post(API_URL + 'set-admin-password', {password, password_confirmation}, {headers: authHeader()})
}*/

const AuthService = {loginUser, getUserData/*, forgotPW, newPassword, setNewPassword*/};
export default AuthService;
