import {React, useRef, useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import Layout from "../../components/Layout/Layout";
import classes from './Login.module.scss';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import AuthService from '../../services/authServices';
import {useNavigate} from "react-router-dom";

const required = (value) => {
    if (!value) {
        return (
            <div className="invalid-feedback absolute left-1/2 -translate-x-1/2 -bottom-5 text-red-500 text-xs">
                Kérjük töltse ki a mezőt!
            </div>
        );
    }
};

const Login = () => {
    const form = useRef();
    const checkBtn = useRef();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const {login} = useAuth();
    const {storeRole} = useAuth();
    const {storeConfirmed} = useAuth();
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            AuthService.loginUser(userName, password).then((res) => {
                console.log(res)
                login({
                    token: res.access_token
                });
                storeRole({
                    role: res.user.role
                })
                storeConfirmed({
                    confirmed: res.user.is_verified
                })
            }, (error) => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || Object.values(error.response.data.error)[0] || error.message || error.toString();
                setLoading(false);
                setMessage(resMessage);
            });
        } else {
            setLoading(false);
        }
    };

    const onChangeUserName = e => {
        const value = e.target.value;
        setUserName(value)
    }

    const onChangePassword = e => {
        const value = e.target.value;
        setPassword(value)
    }

    return (<Layout>
        <div className={"flex w-full items-center justify-center " + classes["background"]}>
            <div className="bg-white px-8 py-10 rounded-md md:w-[540px] mx-8">
                <div className="text-center">
                    <h1 className="font-header font-bold text-2xl text-brandSecondary">JACSOMEDIA VIDEO PORTAL</h1>
                    <p className={'font-base text-xs text-brandSecondary mt-2.5 mb-10 md:mb-12'}>Felhasználói
                        bejelentkezés</p>
                    <div className={'flex flex-col gap-8 mb-5 md:mb-8 items-center'}>
                        <Form onSubmit={handleSubmit} className="flex w-full flex-col gap-7 items-center" ref={form}>
                            <div className="flex flex-col">
                                <label htmlFor="password"
                                       className='flex text-sm font-base font-bold text-brandSecondary mb-1'>Felhasználónév:</label>
                                <Input type="text" id="username" onChange={onChangeUserName} value={userName}
                                       className="login-input" required
                                       placeholder='Add meg a felhasználónevedet.' validations={[required]}/>
                            </div>
                            <div className="flex flex-col">
                                <label htmlFor="password"
                                       className='flex text-sm font-base font-bold text-brandSecondary mb-1'>Jelszó:</label>
                                <Input type="password" id="password" value={password} onChange={onChangePassword}
                                       className="login-input" required
                                       placeholder='Add meg a jelszavadat.' valdations={[required]}/>
                            </div>

                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger text-red-500" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                            <button type="submit" className="btn-login group">
                                Bejelentkezem
                                <svg xmlns="http://www.w3.org/2000/svg" width="22.642" height="11.835"
                                     viewBox="0 0 22.642 11.835"
                                     className="w-5 h-2.5 ml-1 flex self-center fill-current text-black group-hover:text-buttonHover">
                                    <g id="arrow" transform="translate(0 0.707)">
                                        <path id="Path_1" data-name="Path 1" d="M1197.935,677.231l5.21,5.21-5.21,5.21"
                                              transform="translate(-1181.917 -677.231)" fill="none"
                                              stroke="currentColor"
                                              strokeWidth="2"/>
                                        <line id="Line_2" data-name="Line 2" x1="21" transform="translate(0 5.269)"
                                              fill="none" stroke="currentColor" strokeWidth="2"/>
                                    </g>
                                </svg>
                            </button>

                            <CheckButton style={{display: "none"}} ref={checkBtn}/>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </Layout>);
};

export default Login;